import { template as template_32231f4302a946c68a1c99425ebd2f3c } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
const GroupNotificationsTracking = template_32231f4302a946c68a1c99425ebd2f3c(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{false}}
    @showFullTitle={{false}}
    @prefix="groups.notifications"
    class="group-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupNotificationsTracking;
