import { template as template_5468b98f2d0c4b688ca6b69a72c11552 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_5468b98f2d0c4b688ca6b69a72c11552(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
