import { template as template_4689bc0583f14bac85a75184e8ce3a63 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_4689bc0583f14bac85a75184e8ce3a63(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
