import { template as template_956f5f94e8e94f1980864b6066587012 } from "@ember/template-compiler";
const WelcomeHeader = template_956f5f94e8e94f1980864b6066587012(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
