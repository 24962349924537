import { template as template_5938d3e089574759839538cc08b29658 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5938d3e089574759839538cc08b29658(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
