import { template as template_8f71e12aee3a4b4d873412eab3c6ba07 } from "@ember/template-compiler";
const FKLabel = template_8f71e12aee3a4b4d873412eab3c6ba07(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
