import { template as template_d847f3e09f244499adde581faa598b96 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d847f3e09f244499adde581faa598b96(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
